import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import Grid2 from "@mui/material/Grid2";
import Swal from "sweetalert2";
import ChessButton from "./ChessButton";
import { sendMessage } from "../services/websocketManager"; // Adjust path to match your project
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

// 🔧 Optional: mock FBInstant for local testing
const mockFBInstantIfNeeded = () => {
  if (
    typeof window.FBInstant === "undefined" &&
    (process.env.NODE_ENV === "development" ||
      window.location.hostname === "localhost")
  ) {
    console.warn("⚠️ Mocking FBInstant for development/testing...");

    window.FBInstant = {
      payments: {
        getCatalogAsync: () =>
          Promise.resolve([
            {
              productID: "p_1000",
              title: "King’s Treasure",
              description: "Get 1,000 coins instantly",
              imageURI:
                "https://cdn-icons-png.flaticon.com/512/3135/3135715.png",
              price: "$4.99",
            },
            {
              productID: "p_5000",
              title: "Mega Bundle",
              description: "5,000 coins with bonus",
              imageURI:
                "https://cdn-icons-png.flaticon.com/512/3135/3135792.png",
              price: "$19.99",
            },
          ]),
        purchaseAsync: ({ productID }) => {
          console.log("🔁 Mock purchaseAsync called for:", productID);
          return Promise.resolve({
            paymentID: `mock-payment-${productID}`,
            productID,
            signedRequest: "mock-signed-request",
            purchaseTime: Date.now(),
            purchasePrice: productID === "p_1000" ? 499 : 1999,
          });
        },
      },
    };
  }
};

const PurchaseCoinsFacebook = ({ userData, onClose }) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fbAvailable, setFbAvailable] = useState(true);

  useEffect(() => {
    mockFBInstantIfNeeded();

    if (typeof window.FBInstant === "undefined") {
      console.error("❌ FBInstant is not available.");
      setFbAvailable(false);
      setLoading(false);
      return;
    }

    window.FBInstant.payments
      .getCatalogAsync()
      .then((catalog) => {
        console.log("📦 Facebook catalog:", catalog);
        setProducts(catalog);
        setLoading(false);
      })
      .catch((err) => {
        console.error("❌ Failed to fetch Facebook catalog:", err);
        Swal.fire({
          title: t("Error loading products"),
          icon: "error",
          confirmButtonText: "OK",
        });
        setLoading(false);
      });
  }, [t]);

  const handlePurchase = async (productID) => {
    try {
      console.log("🛒 Attempting to purchase:", productID);

      const purchase = await window.FBInstant.payments.purchaseAsync({
        productID,
        developerPayload: JSON.stringify({ userId: userData?.id }),
      });

      const generalMessage = {
        messageType: "facebook_purchase",
        payment: {
          transactionId: purchase.paymentID,
          productId: purchase.productID,
          signedRequest: purchase.signedRequest,
          purchaseTime: purchase.purchaseTime,
          amount: purchase.purchasePrice,
        },
      };

      console.log("📤 Sending purchase to backend:", generalMessage);
      sendMessage(generalMessage); // Hook into your WebSocket backend

      Swal.fire({
        title: t("Purchase Successful!"),
        icon: "success",
        confirmButtonText: "OK",
      });

      if (onClose) onClose();
    } catch (err) {
      console.error("❌ Facebook purchase failed:", err);
      Swal.fire({
        title: t("Payment failed. Please try again."),
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (!fbAvailable) {
    return (
      <Box textAlign="center" mt={4}>
        <Typography variant="h6" color="text.secondary">
          {t("Facebook purchases are only available inside the Facebook game.")}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: "16px" }}>
      <Typography variant="h5" align="center" gutterBottom>
        {t("Buy Coins on Facebook")}
      </Typography>
      <Grid2 container spacing={2} justifyContent="center">
        {products.map((product) => (
          <Grid2 item key={product.productID} xs={12} sm={6} md={4}>
            <Card sx={{ minHeight: 250 }}>
              <CardContent sx={{ textAlign: "center" }}>
                <Typography variant="h6">{product.title}</Typography>
                <img
                  src={product.imageURI}
                  alt={product.title}
                  style={{ width: "100px", height: "100px", margin: "10px 0" }}
                />
                <Typography variant="body1">{product.description}</Typography>
                <Typography
                  variant="h6"
                  sx={{ color: "green", marginTop: "10px" }}
                >
                  {product.price}
                </Typography>
                {/* <ChessButton
                  text={t("Buy")}
                  size="small"
                  primaryColor="gold"
                  hoverColor="gold"
                  fontColor="black"
                  onClick={() => handlePurchase(product.productID)}
                /> */}
                <Button
                onClick={() => handlePurchase(product.productID)}
                variant="contained"
                color="primary"
                >
                {t("Buy")}
                </Button>
              </CardContent>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};

export default PurchaseCoinsFacebook;
