import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HandshakeIcon from "@mui/icons-material/Handshake"; // Icon for offer draw
import SaveIcon from "@mui/icons-material/Save"; // Icon for save game
import InfoIcon from "@mui/icons-material/Info";
import HistoryIcon from "@mui/icons-material/History";
import VisibilityIcon from "@mui/icons-material/Visibility"; // Icon for invite viewer
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FlagIcon from "@mui/icons-material/Flag"; // Icon for surrender
import HomeIcon from '@mui/icons-material/Home';
import CancelIcon from '@mui/icons-material/Cancel';
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions"; // Icon for send smile
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";
import { sendMessage } from "../services/websocketManager";
import { generalMessageTemplate } from "../entities/generalMessageTemplate";
import { useTranslation } from "react-i18next";
import { GENERAL } from "../general/General";
import { useLocation } from "react-router-dom";
import { Share } from "./Share";
import Picker from "emoji-picker-react";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import GameInfoDialog from "./GameInfoDialog";
import Swal from 'sweetalert2';
const ControlPanel = ({
  boardWidth,
  playerColorFromServer,
  gameIdFromServer,
  onCaptureBoard,
  setIsViewer,
  gameOver,
  onAccordionChange,
  onGameHistoryChange,
  getMoveHistory,
  aiLevel,
  gameInfo,
  winnerColor,
  learnMode
  
}) => {
  const location = useLocation();
  const generalMessageViaState = location.state?.generalMessage;
  const { t } = useTranslation();
  const [playerColor, setPlayerColor] = useState(null);
  const [gameId, setGameId] = useState(null);
  const [openSurrenderDialog, setOpenSurrenderDialog] = useState(false); // State for dialog
  const [currentGameUrl, setCurrentGameUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [viewer, setViewer] = useState(false);
  const [openOfferDrawDialog, setOpenOfferDrawDialog] = useState(false); // State for offer draw dialog
  const [offerDrawMessage, setOfferDrawMessage] = useState(""); // Message for offer draw dialog
  const [, setShowPicker] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [gameHistoryOpen, setGameHistoryOpen] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [gameName, setGameName] = useState("");
  const [showHistoryText, setShowHistoryText] = useState(t("Show History"));
  const [emojiDialogOpen, setEmojiDialogOpen] = useState(false); // Manage emoji dialog state
  const [gameInfoDialogOpen, setGameInfoDialogOpen] = useState(false); // State for game info dialog
  
  const navigate = useNavigate();
  const handleSaveGameAction = () => {
    if (gameName.trim()) {
      // Prepare the data to send to the server
      const generalMessage = { ...generalMessageTemplate };
      generalMessage.gameInfo.gameId = gameIdFromServer;
      generalMessage.messageType = "save_game_request";
      generalMessage.gameInfo.gameTeamType = gameInfo
        ? gameInfo.gameTeamType
        : null;
      generalMessage.gameInfo.userType = gameInfo ? gameInfo.userType : null;
      generalMessage.gameInfo.color = playerColor;
      generalMessage.gameInfo.winnerColor = gameOver ? winnerColor : null;
      generalMessage.gameInfo.aiLevel = aiLevel;
      generalMessage.gameInfo.gameName = gameName.trim();
      generalMessage.gameInfo.simpleGameHistory = getMoveHistory();

      sendMessage(generalMessage);

      // Reset and close the dialog
      setGameName("");
      setDialogOpen(false);
    } else {
      
      Swal.fire({
        title: t("Error!"),
        text: t("Save_Game_Name_Required"),
        icon: 'error',
        confirmButtonText: t("OK"),
      })// Show error message if name is empty
    }
  };

  const handleShowGameInfo = () => {
    setExpanded(false);
    if (onAccordionChange) {
      onAccordionChange(false);
    }
    setGameInfoDialogOpen(true);
  };

  const handleCloseGameInfoDialog = () => {
    setGameInfoDialogOpen(false);
  };

  const handleShowHistory = () => {
    setExpanded(false);
    if (onAccordionChange) {
      onAccordionChange(false);
    }
    setGameHistoryOpen(!gameHistoryOpen);
    if (onGameHistoryChange) {
      onGameHistoryChange(gameHistoryOpen);
      if (gameHistoryOpen === true) {
        setShowHistoryText(t("Hide History"));
      } else {
        setShowHistoryText(t("Show History"));
      }
    }
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (onAccordionChange) {
      onAccordionChange(isExpanded);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOfferDraw = () => {
    setExpanded(false);
    if (onAccordionChange) {
      onAccordionChange(false);
    }
    if (generalMessageViaState?.playAgainstAI === true) {
      //setOfferDrawMessage("Unable to offer draw to AI");
    } else {
      setOfferDrawMessage("");
    }
    setOpenOfferDrawDialog(true);
  };

  const handleOfferDrawClose = () => {
    setOpenOfferDrawDialog(false);
  };

  const handleOfferDrawConfirm = () => {
    // Logic to send offer draw message
    const generalMessage = { ...generalMessageTemplate };
    generalMessage.messageType = "offer_draw_request";
    generalMessage.gameInfo.gameId = gameIdFromServer;
    sendMessage(generalMessage);
    setOpenOfferDrawDialog(false);
  };

  useEffect(() => {
    if (setIsViewer) {
      setViewer(setIsViewer);
    }
  }, [setIsViewer]);

  useEffect(() => {
    if (playerColorFromServer != null) {
      setPlayerColor(playerColorFromServer);
    }
  }, [playerColorFromServer]);

  useEffect(() => {
    if (gameIdFromServer != null) {
      setGameId(gameIdFromServer);
    }
  }, [gameIdFromServer]);

  const theme = useTheme();

  // Action Handlers

  const handleEmojiDialogOpen = () => {
    setExpanded(false);
    if (onAccordionChange) {
      onAccordionChange(false);
    }
    setEmojiDialogOpen(true);
  };

  const handleEmojiDialogClose = () => {
    setEmojiDialogOpen(false);
  };

  const handleEmojiClick = (emojiData) => {
    setExpanded(false);
    if (onAccordionChange) {
      onAccordionChange(false);
    }
    const selectedEmoji = emojiData.emoji;

    const emojiMessage = { ...generalMessageTemplate };
    emojiMessage.messageType = "emoji";
    emojiMessage.game.gameId = location.state?.generalMessage.game.gameId;
    emojiMessage.gameInfo.gameId = location.state?.generalMessage.game.gameId;
    emojiMessage.color = playerColor;
    emojiMessage.content = selectedEmoji;

    sendMessage(emojiMessage);

    // Optionally close the picker
    setShowPicker(false);
  };

  const handleSaveGame = () => {
    setExpanded(false);
    if (onAccordionChange) {
      onAccordionChange(false);
    }
    setDialogOpen(true);
  };
  const handleInviteViewer = () => {
    setExpanded(false);
    if (onAccordionChange) {
      onAccordionChange(false);
    }
    let gameURL = GENERAL.HOST + "invited/" + gameId + "/" + playerColor;
    setCurrentGameUrl(gameURL); // Set the current game URL
    handleOpen();
  };
  const handleShareScreen = () => {
    setExpanded(false);
    if (onAccordionChange) {
      onAccordionChange(false);
    }
    onCaptureBoard();
  };

  const handleLeave = () => {
    handleSurrender();
    
  }; 
  
  const handleSurrender = () => {
    setExpanded(false);
    if (onAccordionChange) {
      onAccordionChange(false);
    }
    if(gameOver === false && gameIdFromServer){
      setOpenSurrenderDialog(true);
    }else{
      navigate("/main"); 
    }
    
  }; // Open the dialog

  const confirmSurrender = () => {
    const leaveMessage = { ...generalMessageTemplate };
    leaveMessage.messageType = "leave_game";
    leaveMessage.game.gameId = gameId;
    leaveMessage.color = playerColor;
    sendMessage(leaveMessage);
    setOpenSurrenderDialog(false); // Close the dialog
    console.log("Surrender confirmed.");
  };

  // Define buttons with viewer-specific logic
  const buttons = [
    {
      label: t("Offer Draw"),
      icon: <HandshakeIcon />,
      color: "#00796b",
      hover: "#004d40",
      action: handleOfferDraw,
      hidden: false,
      disabled: learnMode || viewer || gameOver , // Disable button if viewer is true or game is over
    },
    {
      label: t("Save Game"),
      icon: <SaveIcon />,
      color: "#0288d1",
      hover: "#01579b",
      action: handleSaveGame,
      hidden: false,
      disabled: false,
    },
    {
      label: t("Invite Viewer"),
      icon: <VisibilityIcon />,
      color: "#ffa726",
      hover: "#f57c00",
      action: handleInviteViewer,
      hidden: false,
      disabled: learnMode || viewer || gameOver, // Disable button if viewer is true or game is over
    },
    {
      label: t("Share Screen"),
      icon: <CameraAltIcon />,
      color: "#388e3c",
      hover: "#1b5e20",
      action: handleShareScreen,
      hidden: false,
      disabled: false,
    },
    {
      label: showHistoryText,
      icon: <HistoryIcon />,
      color: "#7b5c91",
      hover: "#872cc7",
      action: handleShowHistory,
      hidden: false,
      disabled: false,
    },
    {
      label: t("Send Smile"),
      icon: <EmojiEmotionsIcon />,
      color: "#ffeb3b",
      hover: "#fdd835",
      textColor: "#000",
      action: handleEmojiDialogOpen,
      hidden: false,
      disabled: learnMode || viewer || gameOver, // Disable button if viewer is true or game is over
    },
    {
      label: t("Game Info"),
      icon: <InfoIcon />,
      color: "#2cabc7",
      hover: "#27525c",
      textColor: "#000",
      action: handleShowGameInfo,
      hidden: false,
      disabled: false,
    },
    {
      label: t("Surrender"),
      icon: <FlagIcon />,
      color: "#d32f2f",
      hover: "#b71c1c",
      action: handleSurrender,
      hidden: false,
      disabled: learnMode || viewer || gameOver, // Disable button if viewer is true or game is over
    },
    {
      label: t("Main"),
      icon: <HomeIcon />,
      color: "#4a707d",
      hover: "grey",
      action: handleLeave,
      hidden: false,
      disabled: false
    },
    {
      label: t("Close"),
      icon: <CancelIcon />,
      color: "black",
      hover: "grey",
      action: handleAccordionChange,
      hidden: false,
      disabled: false
    },
  ];

  return (
    <>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{t("Save_Game")}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t("Save_Game_Name")}
            type="text"
            fullWidth
            value={gameName}
            onChange={(e) => setGameName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="secondary">
            {t("Cancel")}
          </Button>
          <Button onClick={handleSaveGameAction} color="primary">
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        sx={{
          position: "sticky", // Ensure it stays within the layout flow
          zIndex: 100, // High zIndex to ensure it's above other elements
          // top: gameOver
          //   ? `calc(${boardWidth}px + 90px)` // Position slightly lower when the game is over
          //   : `calc(${boardWidth}px + 180px)`, // Normal position
          left: "0%", // Center horizontally
          transform: expanded ? "translateY(-80%)" : "translateY(0)", // Move upward when expanded
          transition: "transform 0.3s ease-in-out", // Smooth transition effect
          backgroundColor: "none",
          
          boxShadow: theme.shadows[15],
          maxWidth: "fit-content", // Fit content width
          border: "4px solid #ccc", // Add border around the Stack
         
          borderRadius: "15px",
          //backgroundColor: "#edf2fa", // Primary theme color
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          //aria-controls="control-panel-content"
          id="control-panel-header"
          sx={{
            
            //boxShadow: "0 5px 5px black",
            //backgroundColor: "#ccb", // Primary theme color
            //color: "#fff",
            //padding: "5px",
            //borderRadius: "25px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%", // Ensure it spans the entire width
            }}
          >
            <Typography
              variant="h8"
              sx={{
                fontWeight: "bold",
                textAlign: "center", // Center text
                color: "black", // Set the font color to black
              }}
            >
              {t("Control Panel")}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              padding: "5px",
              width: "fit-content", // Fit the content width
              margin: "0 auto", // Center the panel
            }}
          >
            {/* Buttons in a single column */}
            <Grid
              container
              spacing={1}
              direction="column"
              alignItems="center"
              sx={{ width: "fit-content" }} // Fit content width
            >
              {buttons.map((button, index) => (
                <Grid item key={index} xs={12}>
                  <Button
                    variant="contained"
                    startIcon={button.icon}
                    onClick={button.action}
                    disabled={button.disabled}
                    sx={{
                      width: "150px", // Fixed button width
                      height: "50px",
                      fontSize: "0.75rem",
                      backgroundColor: button.color,
                      color: button.textColor || "#fff",
                      "&:hover": {
                        backgroundColor: button.hover,
                      },
                      textTransform: "none",
                    }}
                  >
                    {button.label}
                  </Button>
                </Grid>
              ))}
            </Grid>

          {/*Game Info Dialog */}
            <GameInfoDialog
              open={gameInfoDialogOpen}
              onClose={() => setGameInfoDialogOpen(false)}
              gameInfo={generalMessageViaState?.gameInfo}
            />

            {/* Offer Draw Dialog */}
            <Dialog open={openOfferDrawDialog} onClose={handleOfferDrawClose}>
              <DialogTitle>{t("Offer Draw")}</DialogTitle>
              <DialogContent>
                {offerDrawMessage ? (
                  <Typography>{offerDrawMessage}</Typography>
                ) : (
                  <Typography>{t("Do you want to offer a draw?")}</Typography>
                )}
              </DialogContent>
              <DialogActions>
                {!offerDrawMessage && (
                  <>
                    <Button onClick={handleOfferDrawClose} color="primary">
                      {t("No")}
                    </Button>
                    <Button onClick={handleOfferDrawConfirm} color="primary">
                      {t("Yes")}
                    </Button>
                  </>
                )}
                {offerDrawMessage && (
                  <Button onClick={handleOfferDrawClose} color="primary">
                    {t("OK")}
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </Box>
        </AccordionDetails>
      </Accordion>
	  
	  {/* Emoji Picker Dialog */}
            <Dialog open={emojiDialogOpen} onClose={handleEmojiDialogClose}>
              <DialogTitle>{t("Pick an Emoji")}</DialogTitle>
              <DialogContent>
                <Picker onEmojiClick={handleEmojiClick} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleEmojiDialogClose} color="primary">
                  {t("Close")}
                </Button>
              </DialogActions>
            </Dialog>

      {/* Dialog Invite Viewer*/}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{t("Invite Viewer dialog")}</DialogTitle>
        <DialogContent>
          <Share currentUrl={currentGameUrl} />
        </DialogContent>
      </Dialog>

      {/* Dialog for Surrender Confirmation */}
      <Dialog
        open={openSurrenderDialog}
        onClose={() => setOpenSurrenderDialog(false)}
      >
        <DialogTitle>{t("Confirm Surrender")}</DialogTitle>
        <DialogContent>
          <Typography>{t("Are you sure you want to surrender?")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSurrenderDialog(false)} color="primary">
            {t("Cancel")}
          </Button>
          <Button
            onClick={confirmSurrender}
            color="secondary"
            variant="contained"
          >
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ControlPanel;
